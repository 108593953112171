import React, { memo, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AspectRatio, Box, Heading, Image, Text, Stack, VStack, Button } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
// import { FormButton } from "@app/components/Form/FormButton"
// import { FormErrors } from "@app/components/Form/FormErrors"

import type { UseGiftcard } from "@app/hooks/useGiftcard"

type Props = UseGiftcard

export const GiftcardForm: React.FC<Props> = memo(({ applied, card, handleApply, handleShow, loading, show }) => {
  const { giftcard } = useStaticQuery<GatsbyTypes.StaticGiftcardFormQuery>(graphql`
    query StaticGiftcardForm {
      giftcard: sanityPageGiftcard {
        image: _rawImage(resolveReferences: { maxDepth: 2 })
        additionalRedeem
        additionalRedeemed
        additionalBalance
        additionalExpiry
        additionalHide
        additionalName
        additionalShow
        title
      }
    }
  `)
  const { getGatsbyImage } = useImage()

  const image = useMemo(() => getGatsbyImage(giftcard?.image), [getGatsbyImage, giftcard?.image])

  return card ? (
    <VStack as="figure" alignItems="stretch" justifyContent="flex-start" w={500} maxW="full" spacing={12.5}>
      <Box as="figcaption" borderRadius="md" color="white" pos="relative" overflow="hidden">
        <AspectRatio ratio={1.75 / 1} w="full">
          <>{image && <Image {...image} alt={image?.alt || giftcard?.title} opacity={0.8} />}</>
        </AspectRatio>
        <>
          <Heading as="h2" pos="absolute" top={5} left={5} fontSize={["xl", "3xl"]} size="h3" color="white">
            {card?.balance?.initial}
          </Heading>
          <Heading
            as="p"
            pos="absolute"
            bottom={5}
            right={5}
            letterSpacing={["generous", "loose"]}
            fontSize={["md", "2xl"]}
            size="h4"
            color="white"
          >
            {show ? card?.code?.full : card?.code?.partial}
          </Heading>
        </>
      </Box>

      <VStack alignItems="center" spacing={3} textAlign="center" sx={{ strong: { fontWeight: 600 } }} py={8}>
        {card?.customer && (
          <Text
            letterSpacing="generous"
            dangerouslySetInnerHTML={{
              __html: giftcard?.additionalName?.replace("{name}", `<strong>${card?.customer?.name || card?.customer?.email}</strong>`),
            }}
          />
        )}
        {card?.expires && (
          <Text
            letterSpacing="generous"
            dangerouslySetInnerHTML={{ __html: giftcard?.additionalExpiry?.replace("{date}", `<strong>${card?.expires}</strong>`) }}
          />
        )}
        <Text
          letterSpacing="generous"
          dangerouslySetInnerHTML={{
            __html: giftcard?.additionalBalance?.replace("{amount}", `<strong>${card?.balance?.remaining}</strong>`),
          }}
        />
      </VStack>

      <VStack alignItems="center" justifyContent="flex-start" spacing={3}>
        <Stack direction={["column", "row"]} justifyContent="center" alignItems="center" spacing={4} w="full">
          <Button
            onClick={handleShow}
            size="md"
            title={show ? giftcard?.additionalHide : giftcard?.additionalShow}
            aria-label={show ? giftcard?.additionalHide : giftcard?.additionalShow}
            variant="secondary"
            w={["full", 1 / 2]}
          >
            {show ? giftcard?.additionalHide : giftcard?.additionalShow}
          </Button>
          {card?.enabled && (
            <Button
              isDisabled={applied}
              isLoading={loading}
              onClick={handleApply}
              size="md"
              title={applied ? giftcard?.additionalRedeemed : giftcard?.additionalRedeem}
              aria-label={applied ? giftcard?.additionalRedeemed : giftcard?.additionalRedeem}
              w={["full", 1 / 2]}
            >
              {applied ? giftcard?.additionalRedeemed : giftcard?.additionalRedeem}
            </Button>
          )}
        </Stack>
        {/* <FormErrors errors={errors} /> */}
      </VStack>
    </VStack>
  ) : null
})
