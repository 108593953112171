import { useCallback, useEffect, useMemo, useState } from "react"

import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

import type { Location } from "@root/types/global"
import { useCartContext } from "@app/providers/cart"
import { useCart } from "./useCart"

type GiftcardBalance = {
  initial: number
  remaining: number
}

type GiftcardBalanceParsed = {
  initial: string
  remaining: string
}

type GiftcardCodeParsed = {
  full: string
  partial: string
}

type GiftcardCustomer = {
  email: string
  name: string
}

type Giftcard = {
  balance: GiftcardBalance
  code: string
  currency: string
  customer: GiftcardCustomer
  enabled: boolean
  expired: string
  expires: string
}

type GiftcardParsed = {
  balance: GiftcardBalanceParsed
  code: GiftcardCodeParsed
  currency: string
  customer: GiftcardCustomer
  enabled: boolean
  expired: string
  expires: string | null
}

export type UseGiftcard = {
  applied: boolean
  card: GiftcardParsed | null
  errors: Array<string>
  handleApply: (event: React.BaseSyntheticEvent) => void
  handleShow: (event: React.BaseSyntheticEvent) => void
  loading: boolean
  show: boolean
}

export const useGiftcard = (location: Location): UseGiftcard => {
  const { cart } = useCartContext()
  const { applyGiftCardCode } = useCart()
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { formatMoney, formatErrors } = useShopify()

  const [card, setCard] = useState<GiftcardParsed | null>(null)
  const [errors, setErrors] = useState<Array<string>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  const getCard = useCallback(() => {
    const parts = location?.pathname?.split(`/`) || []
    const valid = parts?.length === 3
    const rawGiftcard = valid ? parts?.[parts?.length - 1] || null : null

    try {
      const rawCard: Giftcard | null = rawGiftcard ? JSON.parse(decodeBase64(rawGiftcard)) : null

      if (rawCard?.code) {
        const card = {
          ...rawCard,
          balance: {
            initial: formatMoney(rawCard?.balance?.initial),
            remaining: formatMoney(rawCard?.balance?.remaining),
          },
          code: {
            partial: rawCard?.code?.slice(-4)?.padStart(rawCard?.code?.length, "*"),
            full: rawCard?.code?.match(/.{1,4}/g)?.join(" ") || "",
          },
          enabled: rawCard?.enabled && !rawCard?.expired ? true : false,
          expires: rawCard?.expires
            ? new Intl.DateTimeFormat("en-AU", { day: "numeric", month: "long", year: "numeric" }).format(new Date(rawCard.expires))
            : null,
        }
        setCard(card)
      }
    } catch (error) {
      setErrors([(error as Error)?.message])
    }
  }, [decodeBase64, formatMoney, location?.pathname])

  const applied = useMemo(
    () =>
      !!cart?.appliedGiftCards?.find(({ lastCharacters }: { lastCharacters: string }) =>
        card?.code?.full?.toLowerCase()?.includes(lastCharacters?.toLowerCase())
      ),
    [card?.code?.full, cart?.appliedGiftCards]
  )

  const handleApply = useCallback(
    async (event: React.BaseSyntheticEvent) => {
      event.preventDefault()

      setErrors([])
      setLoading(true)
      // @ts-ignore
      const cards = (await applyGiftCardCode(card?.code?.full)) as any
      setLoading(false)

      if (cards?.checkoutUserErrors?.length) setErrors(formatErrors(cards.checkoutUserErrors))
    },
    [applyGiftCardCode, card?.code?.full, formatErrors, setErrors, setLoading]
  )

  const handleShow = useCallback(
    (event: React.BaseSyntheticEvent) => {
      event.preventDefault()

      setShow(active => !active)
    },
    [setShow]
  )

  // intentionally only run once at first render
  useEffect(() => {
    getCard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    applied,
    card,
    errors,
    handleApply,
    handleShow,
    loading,
    show,
  }
}

export type GiftCardBalanceRespnseError = {
  code: number
  field: string
  message: string
}

export type GiftCardBalanceResponse = {
  available_amount?: number
  expiry_date?: Date
  code: number
  errors?: Array<GiftCardBalanceRespnseError>
  message: string
  original_amount?: number
  used_amount?: number
  status: string
  voucher_number?: string
}

export type GiftCardBalance = Omit<GiftCardBalanceResponse, "errors"> & {
  amount?: string
  date?: string
  errors?: Array<string>
}

export type GiftCardData = {
  pin: string
  voucher: string
}
