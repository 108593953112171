import { Container, Box, Heading, VStack } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { memo } from "react"

import { GiftcardForm } from "./GiftcardForm"
import { useGiftcard } from "@app/hooks/useGiftcard"

import type { Props } from "@app/pages/giftcard"
import type { PageProps } from "@root/types/global"

// Note this page is displayed when a customer clicks the gift card email after purchasing
// a gift card product, rather than being a gift card product itself, which is a regular
// shopify product with a product type of "Gift Cards" set

const Giftcard: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { applied, card, errors, handleApply, handleShow, loading, show } = useGiftcard(location)

  const { getContent } = useContent()
  const content = getContent({ content: page?.content })

  return (
    <>
      <Container as="section" maxW="container.sm" pt={[25, 32]} pb={[25, 38]}>
        {card ? (
          <>
            <VStack alignItems="center" justifyContent="flex-start" spacing={4}>
              <Heading size="h2" as="h1" lineHeight={1.75} textAlign="center">
                {page?.title}
              </Heading>
              <Box mt={4}>{content}</Box>
              <GiftcardForm
                applied={applied}
                card={card}
                errors={errors}
                handleApply={handleApply}
                handleShow={handleShow}
                loading={loading}
                show={show}
              />
            </VStack>
          </>
        ) : (
          <VStack alignItems="center" color="grey.lake" justifyContent="flex-start" spacing={8} textAlign="center">
            <Box p={4}>
              <Heading size="h2" as="h1" lineHeight={1.75} textAlign="center">
                {page?.title}
              </Heading>
              <Heading lineHeight={1.75} size="h4">
                {page?.additionalEmpty}
              </Heading>
            </Box>
          </VStack>
        )}
      </Container>
    </>
  )
}

export default memo(Giftcard)
